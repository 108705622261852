import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SupabaseService } from './services/supabase.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterModule, CommonModule]
})
export class AppComponent implements OnInit {
  isAuthenticated = false;

  constructor(private supabaseService: SupabaseService) {}

  async ngOnInit() {
    this.supabaseService.authChanges((_, session) => {
      this.isAuthenticated = !!session;
    });
  }
}
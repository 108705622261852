<header>
  <nav>
    <div class="flex items-center justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <a routerLink="/" class="text-2xl font-bold text-blue-600">Butterfly</a>
      <ul>
        <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
        <li *ngIf="isAuthenticated">
          <a routerLink="/lists" routerLinkActive="active">Lists</a>
        </li>
        <li  *ngIf="!isAuthenticated"><a routerLink="/sign-in" routerLinkActive="active">Sign In</a></li>
      </ul>
    </div>
  </nav>
</header>

<main>
  <router-outlet></router-outlet>
</main>
import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => 
      import('./home/home.component').then(m => m.HomeComponent)
  },
  {
    path: 'lists',
    loadComponent: () => 
      import('./lists/lists.component').then(m => m.ListsComponent),
    canActivate: [authGuard]
  },
  {
    path: 'list/:id',
    loadComponent: () => 
      import('./list/list.component').then(m => m.ListComponent),
    canActivate: [authGuard]
  },
  {
    path: 'sign-in',
    loadComponent: () => 
      import('./sign-in/sign-in.component').then(m => m.SignInComponent)
  }
];
import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { SupabaseService } from '../services/supabase.service';

export const authGuard = async (route: ActivatedRouteSnapshot) => {
  const supabaseService = inject(SupabaseService);
  const router = inject(Router);

  // Allow access if there's a sharing token in the URL
  const sharingToken = route.queryParamMap.get('token');
  const isListRoute = route.url[0]?.path === 'list';

  // Always allow access to list routes with sharing token
  if (isListRoute && sharingToken) {
    return true;
  }

  // Check for authentication for other routes
  const session = await supabaseService.getSession();
  if (!session) {
    // Only redirect if not a shared list route
    if (!isListRoute || !sharingToken) {
      router.navigate(['/']);
      return false;
    }
  }

  return true;
}; 
import { Injectable } from '@angular/core';
import { AuthChangeEvent, createClient, Session, SupabaseClient, User } from '@supabase/supabase-js';
import { environment } from '../../environments/environment';
import { CreateItemRequest, CreateListRequest } from '../types/list.types';
import { v4 as uuid_generate_v4 } from 'uuid';

export interface IUser {
  email: string;
  name: string;
  website: string;
  url: string;
}

interface SupabaseError {
  code?: string;
  message?: string;
  details?: string;
  hint?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabaseClient: SupabaseClient;

  constructor() {
    this.supabaseClient = createClient(environment.supabaseUrl, environment.supabaseKey);
  }

  public async getUser(): Promise<User | null> {
    const { data } = await this.supabaseClient.auth.getUser();
    return data.user;
  }

  public async getSession(): Promise<Session | null> {
    const { data } = await this.supabaseClient.auth.getSession();
    return data.session;
  }

  public async getProfile(): Promise<any> {
    const user = await this.getUser();

    if (!user) {
      throw new Error('No user logged in');
    }

    return this.supabaseClient
      .from('profiles')
      .select('username, website, avatar_url')
      .eq('id', user.id)
      .single();
  }

  public authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void): void {
    this.supabaseClient.auth.onAuthStateChange((event, session) => callback(event, session));
  }

  public signIn(email: string): Promise<any> {
    return this.supabaseClient.auth.signInWithOtp({
      email,
    });
  }

  public signOut(): Promise<any> {
    return this.supabaseClient.auth.signOut();
  }

  public async updateProfile(userUpdate: IUser): Promise<any> {
    const user = await this.getUser();
  
    if (!user) {
      throw new Error('No user logged in');
    }
  
    const update = {
      username: userUpdate.name,
      website: userUpdate.website,
      id: user.id,
      updated_at: new Date().toISOString(),
    };
  
    return this.supabaseClient
      .from('profiles')
      .upsert(update);
  }
  
  async createList(request: CreateListRequest) {
    const { data, error } = await this.supabaseClient
      .from('lists')
      .insert([
        {
          user_id: (await this.supabaseClient.auth.getUser()).data.user?.id,
          name: request.name,
          is_public: false,
          sharing_token: null
        },
      ])
      .select();

    if (error) throw error;
    return { data, error };
  }

  async getLists() {
    const { data, error } = await this.supabaseClient
      .from('lists')
      .select(`
        id,
        name,
        created_at
      `)
      .eq('user_id', (await this.supabaseClient.auth.getUser()).data.user?.id)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return { data, error };
  }

  async getList(listId: string, sharingToken?: string) {
    try {
      console.log('Getting list:', { listId, sharingToken });

      // First, get the list details
      let listQuery = this.supabaseClient
        .from('lists')
        .select('id, name, created_at, is_public, sharing_token, user_id')
        .eq('id', listId)
        .limit(1);

      // If no sharing token, verify ownership
      if (!sharingToken) {
        const user = await this.getUser();
        if (user) {
          listQuery = listQuery.eq('user_id', user.id);
        }
      }

      console.log('Fetching list details...');
      const { data: listResults, error: listError } = await listQuery;
      console.log('List response:', { listResults, listError });

      if (listError) {
        console.error('List error:', listError);
        throw listError;
      }

      if (!listResults || listResults.length === 0) {
        console.log('No list found');
        return { data: null, error: null };
      }

      const listData = listResults[0];

      // Verify sharing token if provided
      if (sharingToken) {
        if (!listData.is_public || listData.sharing_token !== sharingToken) {
          console.log('List is not public or token mismatch');
          return { data: null, error: null };
        }
      }

      // Then get the items
      const itemsQuery = this.supabaseClient
        .from('items')
        .select('id, title, created_at, status, claimed_by, claimer_name')
        .eq('list_id', listId)
        .order('created_at', { ascending: true });

      console.log('Fetching list items...');
      const { data: itemsData, error: itemsError } = await itemsQuery;
      console.log('Items response:', { itemsData, itemsError });

      if (itemsError) {
        console.error('Items error:', itemsError);
        throw itemsError;
      }

      // Combine the data
      const combinedData = {
        ...listData,
        items: itemsData || []
      };

      console.log('Combined data:', combinedData);
      return { data: combinedData, error: null };
    } catch (error: unknown) {
      console.error('Error in getList:', error);
      if (error && typeof error === 'object' && 'code' in error) {
        const supabaseError = error as SupabaseError;
        console.error('Error details:', {
          code: supabaseError.code,
          msg: supabaseError.message,
          details: supabaseError.details,
          hint: supabaseError.hint
        });
      }
      return { data: null, error };
    }
  }

  async addItemToList(listId: string, request: CreateItemRequest) {
    const { data, error } = await this.supabaseClient
      .from('items')
      .insert([
        {
          list_id: listId,
          title: request.title,
        },
      ])
      .select();

    if (error) throw error;
    return { data, error };
  }

  async toggleListSharing(listId: string): Promise<{ data: any, error: any }> {
    const { data, error } = await this.supabaseClient
      .from('lists')
      .update({ 
        is_public: true,
        sharing_token: uuid_generate_v4()
      })
      .eq('id', listId)
      .select('sharing_token')
      .single();

    if (error) throw error;
    return { data, error };
  }

  async disableListSharing(listId: string): Promise<{ data: any, error: any }> {
    // First, verify user wants to break existing links
    if (!confirm(
      'Warning: This will invalidate all existing share links for this list. ' +
      'Anyone with the current link will no longer be able to access the list. ' +
      'Are you sure you want to continue?'
    )) {
      return { data: null, error: null };
    }

    const { data, error } = await this.supabaseClient
      .from('lists')
      .update({ 
        is_public: false,
        sharing_token: null  // This invalidates the token
      })
      .eq('id', listId)
      .single();

    if (error) throw error;
    return { data, error };
  }

  async deleteList(listId: string): Promise<{ data: any, error: any }> {
    const { data, error } = await this.supabaseClient
      .from('lists')
      .delete()
      .eq('id', listId);

    if (error) throw error;
    return { data, error };
  }

  async deleteListItem(itemId: string): Promise<{ data: any, error: any }> {
    const { data, error } = await this.supabaseClient
      .from('items')
      .delete()
      .eq('id', itemId);

    if (error) throw error;
    return { data, error };
  }

  async claimItem(itemId: string, claimerName?: string): Promise<{ data: any, error: any }> {
    const user = await this.getUser();
    const { data, error } = await this.supabaseClient
      .from('items')
      .update({ 
        status: 'claimed',
        claimed_by: user?.id || null,
        claimer_name: claimerName || user?.email || null
      })
      .eq('id', itemId)
      .select();

    if (error) throw error;
    return { data, error };
  }

  async unclaimItem(itemId: string): Promise<{ data: any, error: any }> {
    const { data, error } = await this.supabaseClient
      .from('items')
      .update({ 
        claimed_by: null,
        status: 'available',
        claimer_name: null
      })
      .eq('id', itemId)
      .select();

    if (error) throw error;
    return { data, error };
  }
}
